import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <div style={styles.container}>
            <Helmet>
                <title>Privacy Policy - MailAttach</title>
                <meta name="description" content="Learn about how MailAttach handles your privacy and data." />
                <meta name="robots" content="noindex, nofollow" />
                {/* Prevent indexing */}
            </Helmet>
            <h1>Privacy Policy for MailAttach - Attachment Alert for Gmail</h1>
            <p>Effective Date: 19 August 2024</p>

            <h2>1. Introduction</h2>
            <p>
                MailAttach ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains
                how we collect, use, disclose, and safeguard your information when you use our Chrome extension,
                "MailAttach - Attachment Alert for Gmail" (the "Extension"). Please read this privacy policy carefully.
                If you do not agree with the terms of this privacy policy, please do not use the Extension.
            </p>

            <h2>2. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
                <li>
                    <strong>Personally Identifiable Information (PII):</strong> We collect information such as your name, email address,
                    and any other information that you provide directly to us when interacting with our services.
                </li>
                <li>
                    <strong>Personal Communications:</strong> We access the body of your emails to detect mentions of attachments using AI.
                    To provide this service, we may share the text body of the email, with third-party service providers
                    that run the necessary AI models. This means we do not send any identifying information such as email addresses, sender
                    information, or recipient details. The content shared is strictly limited to what is necessary for detecting missing attachments.
                </li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect in the following ways:</p>
            <ul>
                <li>
                    <strong>To provide and maintain our Service:</strong> We analyze the body of your emails using AI to remind you to attach
                    files when necessary. This processing is performed by third-party service providers.
                </li>
                <li>
                    <strong>To improve our Service:</strong> We may use feedback you provide to improve our products and services.
                </li>
                <li>
                    <strong>Marketing and Communications:</strong> We may use your contact information (such as your email address)
                    to send you information about our services, including promotional content, provided you have given us explicit consent
                    to do so. You can opt out of these communications at any time by following the unsubscribe instructions included in our emails.
                </li>
            </ul>

            <h2>4. Disclosure of Your Information</h2>
            <p>We do not sell or transfer your user data to third parties for their marketing purposes. However, we may disclose your information in the following situations:</p>
            <ul>
                <li>
                    <strong>Third-Party Service Providers:</strong> We may share details with third-party service providers
                    that assist us in providing our service (such as running AI models or hosting our servers).
                </li>
                <li>
                    <strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to comply
                    with legal obligations, we may share your information as permitted or required by any applicable law, rule, or regulation.
                </li>
                <li>
                    <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be
                    transferred as part of the transaction. You will be notified of any such change in ownership or control of your personal information.
                </li>
            </ul>

            <h2>5. Data Security</h2>
            <p>
                We use administrative, technical, and physical security measures to help protect your personal information. While we have taken
                reasonable steps to secure the personal information you provide to us, please be aware that no security measures are perfect or
                impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>

            <h2>6. Third-Party Services</h2>
            <p>
                Our Extension integrates with third-party services to provide AI infrastructure and functionality for running AI models necessary
                for detecting missing attachments as well as to deploy and serve our servers. We ensure that only the necessary details is shared with these third-party services.
            </p>

            <h2>7. Your Data Protection Rights</h2>
            <p>Depending on your location, you may have the following rights regarding your personal data:</p>
            <ul>
                <li><strong>The right to access:</strong> You have the right to request copies of your personal data.</li>
                <li><strong>The right to rectification:</strong> You have the right to request that we correct any information you believe is inaccurate.</li>
                <li><strong>The right to erasure:</strong> You have the right to request that we erase your personal data, under certain conditions.</li>
            </ul>

            <h2>9. Changes to This Privacy Policy</h2>
            <p>
                If we make significant changes to this Privacy Policy, we will notify you either through the email address you have provided us
                or by placing a prominent notice on our website or within the Extension. Minor changes or clarifications will take effect
                immediately upon being posted on this page, and we encourage you to review our Privacy Policy regularly.
            </p>

            <h2>10. Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <p>By email: hello@mailattach.co</p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
        lineHeight: '1.6',
        color: '#333',
        fontSize: '14px',
        marginLeft: '14%',
        marginRight: '14%',
    },
};

export default PrivacyPolicy;
