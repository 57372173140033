import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/logo.png';
import './Footer.css'; // Import the CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="topSection">
                <div className="left">
                    <img src={logo} alt="MailAttach Logo" className="logo"/>
                    <p>Missing-Attachments Alerts for Gmail</p>
                </div>
                <div className="right">
                    <a href="mailto:hello@mailattach.co">hello@mailattach.co</a>
                    <br/>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </div>
            </div>
            <div className="center">
                <p>&copy; {new Date().getFullYear()} MailAttach. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
