import React from 'react';
import logo from '../assets/logo.png';
import './Header.css';
import {Link} from "react-router-dom"; // Import the CSS file

const Header = () => {
    return (
        <header className="header">
            <Link to="/"> <img src={logo} alt="MailAttach Logo" className="logo"/></Link>
        </header>
    );
};

export default Header;
