// src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../firebase';
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import './LoginPage.css'; // We'll create this CSS file for custom styles

const production = true;
let SERVER_URL = production ? "https://mail-attach-430209.ew.r.appspot.com" : "http://localhost:8080";

function LoginPage() {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const { email, displayName } = result.user;

            console.log(email, displayName);
            // Call the /user endpoint to save user data
            const response = await fetch(SERVER_URL + '/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    fullName: displayName,
                    acceptedPrivacy: true,
                    acceptedTC: true,
                }),
            });

            const data = await response.json();

            console.log(data);

            // If the user was created, send an intro email
            if (data && data.status === 'created') {
                await fetch(SERVER_URL + '/send-intro-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        fullName: displayName,
                    }),
                });
            }

            // Navigate to an external URL (e.g., Gmail)
            window.location.href = 'https://mail.google.com/mail/#inbox?compose=new';

        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>{user ? `Welcome, ${user.displayName}` : 'Welcome to MailAttach!'}</h2>
                <h4 className="subtitle">{user ? '' : 'Please sign in with your Google account in order to use the extension.'}</h4>
                {user ? (
                    <button onClick={handleSignOut}>Disconnect</button>
                ) : (
                    <button onClick={handleGoogleLogin}>Continue with Google</button>
                )}
                <div className="terms-message">
                    <p>
                        By continuing, you agree to our{' '}
                        <a href="/privacy-policy">Privacy Policy</a> and{' '}
                        <a href="/terms-and-conditions">Terms and Conditions</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
