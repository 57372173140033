import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    return (
        <div style={styles.container}>
            <Helmet>
                <title>Terms and Conditions - MailAttach</title>
                <meta name="description" content="Read the terms and conditions of using MailAttach." />
                <meta name="robots" content="noindex, nofollow" /> {/* Prevent indexing */}
            </Helmet>
            <h1>Terms and Conditions</h1>
            <p>Effective Date: 12 August 2024</p>

            <h2>1. Introduction</h2>
            <p>
                Welcome to MailAttach. These terms and conditions outline the rules and regulations for the use of the
                MailAttach Chrome extension (the "Extension"). By accessing or using the Extension, you accept these terms
                and conditions. If you disagree with any part of these terms, please do not use the Extension.
            </p>

            <h2>2. License</h2>
            <p>
                MailAttach grants you a limited, non-exclusive, non-transferable, and revocable license to use the
                Extension solely for your personal, non-commercial use. You may not copy, modify, distribute, or reverse
                engineer any part of the Extension.
            </p>

            <h2>3. User Responsibilities</h2>
            <p>
                By using the Extension, you agree to:
            </p>
            <ul>
                <li>Use the Extension in accordance with all applicable laws and regulations.</li>
                <li>Not use the Extension for any unlawful or malicious purpose.</li>
                <li>Not attempt to gain unauthorized access to any part of the Extension.</li>
            </ul>

            <h2>4. Limitation of Liability</h2>
            <p>
                MailAttach and its affiliates, licensors, and partners are not liable for any direct, indirect, incidental,
                special, or consequential damages arising from your use of the Extension. Your use of the Extension is at
                your own risk.
            </p>

            <h2>5. Termination</h2>
            <p>
                We may terminate or suspend your access to the Extension at any time, without prior notice or liability,
                for any reason whatsoever, including without limitation if you breach the Terms.
            </p>

            <h2>6. Governing Law</h2>
            <p>
                These terms and conditions are governed by and construed in accordance with the laws of [Your Country], and
                you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            </p>

            <h2>7. Changes to These Terms</h2>
            <p>
                MailAttach reserves the right to modify or replace these terms and conditions at any time. We will notify
                you of any changes by posting the new terms and conditions on this page. You are advised to review these
                terms periodically for any changes.
            </p>

            <h2>8. Contact Us</h2>
            <p>
                If you have any questions about these Terms and Conditions, please contact us:
            </p>
            <p>
                By email: hello@mailattach.co
            </p>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Inter, sans-serif',
        lineHeight: '1.6',
        color: '#333',
        fontSize: "14px",
        marginLeft: '14%',
        marginRight: '14%'
    },
};

export default TermsAndConditions;
